.resume-file {
    width: 100%;
    height: 650px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
}

.res-div {
    position: relative;
    height: 100%;
}