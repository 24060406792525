.navbar {
    background-color: black;
    width: 100%;
    height: 100px;
}

.navlist li a {
    text-decoration: none; /* no underlines */
    color: white;/* no purple when clicked */
    margin-right: 60px;
}

.navbar ul {
    list-style-type: none; /* no bullet points*/
    display: flex; 
    margin-top: 0;
    padding: 35px;
    width: 100%;
    justify-content: space-between;
}/* makes buttons side by side and makes it right next to each other*/ 

.bars {
    display: none;
}

@media(max-width: 650px) {

    .bars {
        display: block;
        color: white;
        height: 35px;
        width: 80px;
    }

    .bars-container {
        margin-left: 85%;
    }

    .navbar ul {
        display: none;
    }

    .navbar {
        flex-wrap: nowrap !important;
    }

    .navbar ul.displayMenu {
        display: block;
        position: absolute;
        top: 85px;
        right: 0px;
        width: 140px;
        padding: 10px; 
        background-color: black;
        font-size:larger;
        z-index: 9999;
    }

    ul.displayMenu li {
        margin-bottom: 30px;
    }
}