.about-header {
    font-size: 80px;
}

.headshot{
    height: 500px;
    width: 400px;
    margin-left: 15px;
    float: right;
}

.about-container {
    display: inline-block;
    
}

.about-text p {
    font-size: larger;
}

.bottom-text {
    margin-bottom: 50px;
    display: block;
}