.card {
    width: 250px;
    margin: 10px;
}

.array-cards-container{
    display: flex;
    flex-wrap: wrap;
    margin: 45px;
    justify-content: center;
}

.card-img-top {
    width: 100%;
    height: 150px;
}

.btn {
    width: 50%;
    
}

.btn-container {
    display: flex;
    justify-content: center;
}

.card-body {
    background-color:grey;
}